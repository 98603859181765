// 底部1联系我们
<template>
  <div class="main">
    <div class="top">CONTACT US</div>
    <div class="contcat-part">
      <div class="contcat-text">
        Our offices are open Monday to Friday 9am-5pm (except Bank Holidays)
      </div>
      <div class="contcat-text">
        Call our friendly knowledgeable sales support team now on
        0086-574-62165998
      </div>
      <div class="contcat-text">
        ALY HOME online portal is available to use 24 hours a day, 7 days a
        week.
      </div>
      <div class="contcat-text">You can also email us at sale@yyaly.com.</div>
      <div class="contcat-text">
        Should you wish to write to us, please address your letter to ALY HOME,
        No. 23, Ruhu West Road, Simen, Yuyao, Ningbo, China.
      </div>
      <div class="contcat-title">Online consultation</div>
      <el-button class="contcat-btn" @click="onContcat()"
        >CONTCAT FORM</el-button
      >
      <div class="contcat-title">Send us your enquiry</div>
      <div class="contcat-form">
        <el-form ref="form" :model="form">
          <el-form-item>
            <el-input
              clearable
              type="text"
              v-model="form.name"
              placeholder="Name"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-input
              clearable
              type="text"
              v-model="form.sourceMailbox"
              placeholder="Email"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-input
              clearable
              :autosize="{ minRows: 3 }"
              resize="none"
              type="textarea"
              v-model="form.content"
              placeholder="Enquiry"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button class="submit-btn" @click="onSubmit">Submit</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
import { usVisitLogInsert, usConsultOperation } from "@/api/home.js";
export default {
  name: "ContactUs",
  data() {
    return {
      ossPrefix: "https://blhc806.oss-cn-hangzhou.aliyuncs.com/wmUsImg/",
      form: {
        name: "",
        sourceMailbox: "",
        content: "",
      },
    };
  },
  activated() {
    // console.log("activated");
    this.$nextTick(() => {
      this.form = {
        name: "",
        sourceMailbox: "",
        content: "",
      };
    });
    this.addVisitLogInsert();
  },
  methods: {
    onContcat() {
      this.$store.commit("showIframe", true);
    },
    addVisitLogInsert() {
      let website = window.location.href;
      let websiteTitle = "ContactUs";
      // console.log(website, websiteTitle);
      usVisitLogInsert(website, websiteTitle, 0).then(() => {});
    },
    onSubmit() {
      // console.log("submit!", this.form);
      usConsultOperation(this.form).then((res) => {
        if (res.code == 1) {
          this.$message.success(res.message);
          setTimeout(() => {
            this.form = {
              name: "",
              sourceMailbox: "",
              content: "",
            };
          }, 500);
        } else {
          this.$message.error(res.message);
        }
      });
    },
  },
};
</script>
<style scoped>
.main {
  width: 100%;
  box-sizing: border-box;
}
.top {
  width: 1920px;
  height: 160px;
  font-weight: bold;
  font-size: 34px;
  color: #fff;
  background-image: url("https://blhc806.oss-cn-hangzhou.aliyuncs.com/wmUsImg/sysImg/os_bg_con@2x.png");
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}
.contcat-part {
  width: 1110px;
  margin: 63px auto 0;
}
.contcat-text {
  font-size: 16px;
  color: #293744;
  margin-bottom: 16px;
}
.contcat-title {
  font-size: 20px;
  color: #293744;
  margin: 41px 0 20px;
}
.contcat-form {
  width: 540px;
}

/* 覆盖默认的el-button颜色 */
.el-button {
  width: 540px !important;
  height: 40px !important;
  color: #262626;
  background-color: #fff; /* 设置按钮的默认颜色 */
  border-color: #cccccc; /* 设置边框颜色 */
}

/* 鼠标悬停时的颜色 */
.el-button:hover {
  color: #fff;
  background-color: #caa68a; /* 设置按钮的默认颜色 */
  border-color: #caa68a; /* 设置边框颜色 */
}
.contcat-form >>> .el-input__inner {
  background-color: #eff2f5;
  color: #293744;
}
.contcat-form >>> .el-textarea__inner {
  background-color: #eff2f5;
  color: #293744;
}
.contcat-form >>> .el-input__inner:focus {
  border-color: #999999; /* 边框 */
}
.contcat-form >>> .el-textarea__inner:focus {
  border-color: #999999; /* 边框 */
}
</style>
